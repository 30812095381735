<template lang="pug">
  .policy.wrapper
    .policy_block
      .policy_wrap
        h2 {{ $t('legalDocs.appendix2.title') }}
        .policy_updated {{ $t('legalDocs.lastUpdated') }}: {{ updatedLegal(updatedAt, this.$i18n.locale) }}
        .policy_subheader {{ $t('legalDocs.appendix2.termsAndDefinitions') }}
        p(v-html="$t('legalDocs.appendix2.term1')")
        p(v-html="$t('legalDocs.appendix2.term10')")
        p(v-html="$t('legalDocs.appendix2.term2')")
        p(v-html="$t('legalDocs.appendix2.term3')")
        p(v-html="$t('legalDocs.appendix2.term4')")
        p(v-html="$t('legalDocs.appendix2.term5')")
        p(v-html="$t('legalDocs.appendix2.term6')")
        p(v-html="$t('legalDocs.appendix2.term7')")
        p(v-html="$t('legalDocs.appendix2.term8')")
        p(v-html="$t('legalDocs.appendix2.term9')")
      .policy_wrap
        ol.sublist
          li.policy_wrap(v-html="$t('legalDocs.appendix2.point1')")
          li.policy_wrap(v-html="$t('legalDocs.appendix2.point2')")
          li.policy_wrap(v-html="$t('legalDocs.appendix2.point3')")
          li.policy_wrap(v-html="$t('legalDocs.appendix2.point4')")
          li.policy_wrap(v-html="$t('legalDocs.appendix2.point5')")
          li#early-start.policy_wrap(v-html="$t('legalDocs.appendix2.point6')")
          li.policy_wrap(v-html="$t('legalDocs.appendix2.point20')")
          li.policy_wrap(v-html="$t('legalDocs.appendix2.point7')")
          v-simple-table#table1.policy_table
            thead
              tr
                th {{ $t('legalDocs.appendix2.tier', { tierNumber: '' }) }}
                th {{ $t('legalDocs.appendix2.numberOfDirectReferrals') }}
                th {{ $t('legalDocs.appendix2.reward') }}*
            tbody
              tr
                td #[strong {{ $t('legalDocs.appendix2.tier', { tierNumber: 1 }) }}]
                td 5
                td {{ usePrice(50000, $i18n.locale) }} {{ $t('legalDocs.appendix2.kickTokens') }}
              tr
                td #[strong {{ $t('legalDocs.appendix2.tier', { tierNumber: 2 }) }}]
                td 25
                td {{ usePrice(100000, $i18n.locale) }} {{ $t('legalDocs.appendix2.kickTokens') }}
              tr
                td #[strong {{ $t('legalDocs.appendix2.tier', { tierNumber: 3 }) }}]
                td 50
                td {{ usePrice(150000, $i18n.locale) }} {{ $t('legalDocs.appendix2.kickTokens') }}
              tr
                td #[strong {{ $t('legalDocs.appendix2.tier', { tierNumber: 4 }) }}]
                td 100
                td {{ usePrice(200000, $i18n.locale) }} {{ $t('legalDocs.appendix2.kickTokens') }}
              tr
                td #[strong {{ $t('legalDocs.appendix2.tier', { tierNumber: 5 }) }}]
                td 250
                td {{ usePrice(300000, $i18n.locale) }} {{ $t('legalDocs.appendix2.kickTokens') }}
              tr
                td #[strong {{ $t('legalDocs.appendix2.tier', { tierNumber: 6 }) }}]
                td 500
                td {{ usePrice(400000, $i18n.locale) }} {{ $t('legalDocs.appendix2.kickTokens') }}
              tr
                td #[strong {{ $t('legalDocs.appendix2.tier', { tierNumber: 7 }) }}]
                td 1000
                td {{ usePrice(500000, $i18n.locale) }} {{ $t('legalDocs.appendix2.kickTokens') }}
              tr
                td #[strong {{ $t('legalDocs.appendix2.tier', { tierNumber: 8 }) }}]
                td 1500
                td {{ usePrice(600000, $i18n.locale) }} {{ $t('legalDocs.appendix2.kickTokens') }}
              tr
                td #[strong {{ $t('legalDocs.appendix2.tier', { tierNumber: 9 }) }}]
                td 2000
                td {{ usePrice(1000000, $i18n.locale) }} {{ $t('legalDocs.appendix2.kickTokens') }}
              tr
                td #[strong {{ $t('legalDocs.appendix2.tier', { tierNumber: 10 }) }}]
                td 2500
                td {{ usePrice(2000000, $i18n.locale) }} {{ $t('legalDocs.appendix2.kickTokens') }}
              tr
                td #[strong {{ $t('legalDocs.appendix2.tier', { tierNumber: 11 }) }}]
                td 3000
                td {{ usePrice(3000000, $i18n.locale) }} {{ $t('legalDocs.appendix2.kickTokens') }}
            tfoot
              tr
                td(colspan="3", v-html="$t('legalDocs.appendix2.rewardsTableFootnote')")
          //- li.policy_wrap(v-html="$t('legalDocs.appendix2.point8')")
          //- v-simple-table#table2.policy_table.prize
            thead
              tr
                th {{ $t('legalDocs.appendix2.division') }}
                th {{ $t('legalDocs.appendix2.usersPerDivision') }}
                th {{ $t('legalDocs.appendix2.rewardPerDivision') }}
                th {{ $t('legalDocs.appendix2.percentageOfTotalReward') }}
            tbody
              tr
                td {{ $t('legalDocs.appendix2.diamond') }} {{ $t('legalDocs.appendix2.top100') }}
                td {{ $t('legalDocs.appendix2.top100') }}
                td {{ usePrice(100000000, $i18n.locale) }}
                td {{ usePrice(11.11, $i18n.locale) }}%
              tr
                td {{ $t('legalDocs.appendix2.platinum') }}
                td {{ $t('legalDocs.appendix2.percentOfAllParticipants', { percent: 7.5 }) }}*
                td {{ usePrice(300000000, $i18n.locale) }}
                td {{ usePrice(33.33, $i18n.locale) }}%
              tr
                td {{ $t('legalDocs.appendix2.gold') }}
                td {{ $t('legalDocs.appendix2.percentOfAllParticipants', { percent: 15 }) }}*
                td {{ usePrice(250000000, $i18n.locale) }}
                td {{ usePrice(27.78, $i18n.locale) }}%
              tr
                td {{ $t('legalDocs.appendix2.silver') }}
                td {{ $t('legalDocs.appendix2.percentOfAllParticipants', { percent: 20 }) }}*
                td {{ usePrice(150000000, $i18n.locale) }}
                td {{ usePrice(16.67, $i18n.locale) }}%
              tr
                td {{ $t('legalDocs.appendix2.bronze') }}
                td {{ $t('legalDocs.appendix2.percentOfAllParticipants', { percent: 25 }) }}*
                td {{ usePrice(100000000, $i18n.locale) }}
                td {{ usePrice(11.11, $i18n.locale) }}%
              tr
                td {{ $t('legalDocs.appendix2.newbie') }}
                td {{ $t('legalDocs.appendix2.percentOfAllParticipants', { percent: 32.5 }) }}*
                td(colspan="2") {{ $t('legalDocs.appendix2.noRewardAllocated') }}
              tr.total
                td(colspan="2") {{ $t('legalDocs.appendix2.totalReward') }}
                td(colspan="2") {{ usePrice(900000000, $i18n.locale) }} KICK
            tfoot
              tr
                td(colspan="9", v-html="$t('legalDocs.appendix2.prizeDistributionTableFootnote')")
          li#welcome-bonus.policy_wrap(v-html="$t('legalDocs.appendix2.point9')")
          li.policy_wrap(v-html="$t('legalDocs.appendix2.point11')")
          li.policy_wrap
            strong {{ $t('legalDocs.appendix2.generalRequirements') }}
            ol.sublist
              li.policy_wrap(v-html="$t('legalDocs.appendix2.point12')")
              li.policy_wrap(v-html="$t('legalDocs.appendix2.point13')")
              li.policy_wrap(v-html="$t('legalDocs.appendix2.point14')")
              li.policy_wrap(v-html="$t('legalDocs.appendix2.point15')")
              li.policy_wrap(v-html="$t('legalDocs.appendix2.point16')")
              li.policy_wrap(v-html="$t('legalDocs.appendix2.point17')")
              li.policy_wrap(v-html="$t('legalDocs.appendix2.point18')")
              li.policy_wrap(v-html="$t('legalDocs.appendix2.point21')")
              li.policy_wrap(v-html="$t('legalDocs.appendix2.point19')")
      p.policy_wrap
        router-link(to="/terms") {{ $t('legalDocs.goBackToTermsOfUse')}}
</template>

<script>
import { currency, updatedLegal } from '@/dates';

export default {
  data() {
    return {
      scrollOptions: {
        easing: 'easeOutCubic',
        duration: 900,
        offset: 150
      },
      updatedAt: 'updatedAt: 2021-06-30'
    };
  },
  methods: {
    usePrice(str, locale) {
      return currency(str, locale);
    },
    updatedLegal(date, locale) {
      return updatedLegal(date, locale);
    },
    goTo(anchor) {
      this.$vuetify.goTo(anchor, this.scrollOptions);
    }
  }
};
</script>

<style lang="scss" scoped>
  @import '../assets/scss/variables';

  .policy_table {
    margin: 30px 0;

    tbody tr:not(:last-child) td:not(.v-data-table__mobile-row),
    thead tr:last-child th {
      border-bottom: 1px solid $blue !important;
    }

    thead th {
      color: $blue !important;
      text-transform: uppercase;
    }

    &.prize {
      tbody tr td:first-child { text-transform: uppercase; }

      tr.total { font-weight: 700; }
    }

    tbody tr:hover { background: $drop-overlay-background !important; }
  }

  .sublist {
    counter-reset: item !important;

    li { display: block; }

    li::before {
      left: -45px !important;
      content: counters(item, '.') '. ' !important;
      counter-increment: item !important;
    }
  }
</style>
